.Company-info {
  padding-bottom: 15px;
}

.Company-info h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.Company-info span {
  font-size: 14px;
  line-height: 19px;
  display: block;
  margin-top: 5px;
}

.Company-info .DunsAbbr {
  display: inline-block;
  font-weight: bold;
}

.Company-info .remove {
  float: right;
  padding: 1px 12px;
  border-radius: 3px;
  border: none;
  background-color: #f2f2f2;
}
