.Contact {
  background-color: #f1f6f8;
}

.Contact .Contact-h3 {
  font-size: 16px;
  color: #005172;
  font-weight: 600;
}

.Contact .Contact-h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}

.Contact .Title-paragraph {
  margin-bottom: 30px;
  font-weight: 600;
  color: #005172;
  font-size: 16px;
}

.Contact .countries-list {
  margin-bottom: 20px;
}

.Contact .Contact-hr {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #d7e0e4;
}

.Contact .Chat-button:hover,
.Contact .Chat-button:active {
  color: #ffffff;
  background-color: #0088b1;
}

.Contact .Chat-button:disabled {
  color: #ffffff;
  background-color: #b1d8e6;
}

.Contact .Contact-form {
  background-color: #ffffff;
  margin-top: 150px;
}

.Contact .logo2 {
  margin-top: 40px;
}

.Contact .row {
  background-color: #ffffff;
}

@media (max-width: 992px) {
  .Contact .Contact-form {
    margin-top: 40px;
  }
}

.Contact .back-login {
  text-align: center;
  margin-top: 30px;
}

.Contact .login-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}
