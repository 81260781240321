.PublicHeader .container {
  width: 100%;
}

.PublicHeader .Public-navbar > li {
  float: left;
}

.PublicHeader .navbar-text {
  margin-right: 30px;
}

.PublicHeader .Public-navbar li > a#language-nav-dropdown {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .PublicHeader .Public-navbar {
    box-shadow: none;
    margin-right: 20px;
  }
}
