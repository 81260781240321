.User-settings,
.Company-settings {
  padding: 7px 20px;
}

.User-settings .Link,
.Company-settings .Link {
  float: right;
}

.User-image {
  width: 62px;
  height: 62px;
  border-radius: 50px;
}

.AppWrapper-content .User-image,
.AppWrapper-content .Colored-circle {
  width: 100px;
  height: 100px;
}

.User-details,
.Company-details {
  margin-left: 15px;
  overflow: hidden;
  width: 100%;
}

.User-details h4 {
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.User-details p,
.Company-details p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
