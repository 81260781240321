.delivery-step .delivery-step-certificate {
  background-repeat: no-repeat;
  background-position: center;
  min-height: 261px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delivery-step .delivery-step-heading {
  font-weight: 600;
  font-size: 32px;
  color: #00b2a9;
}

.delivery-step .delivery-step-subheading {
  font-weight: 600;
  font-size: 24px;
  color: #0078b0;
}

.delivery-step .delivery-step-text {
  font-weight: 600;
}
