.dbia-credentials-link {
  position: sticky;
  text-align: right;
  padding-top: 10px;
}
.dbia-dropdown {
  padding-left: 0px;
}
.dbia-dropdown .dropdown-toggle {
  color: #337ab7;
  text-decoration: none;
}
.dbia-login iframe {
  height: 100vh;
  border: 0;
}
