.UniverseList .Universe-button {
  width: 100%;
  background-color: #f6f6f6;
  margin-bottom: 10px;
  border: 1px solid #9d9d9d;
  border-radius: 3px;
  color: black;
  padding: 20px;
}

.UniverseList .Universe-button .Title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #005172;
}

.UniverseList .Universe-button .Info {
  color: #333333;
  font-size: 14px;
  line-height: 19px;
  margin-top: 5px;
}

.UniverseList .add-universe-btn {
  float: right;
  margin-right: 15px;
}

.UniverseList .Content-table-last {
  padding: 0 20px;
}

.UniverseList .Title-table {
  background-color: #f6f6f6;
}

.UniverseList .Empty-list-message {
  margin: 0 30px;
}
