.report-modal .modal-dialog {
  width: 90%;
}

.reportsPurchaseTableInner .th-orderdate {
  min-width: 95px;
}

.reportsPurchaseTableInner .th-actions {
  min-width: 255px;
}
