.Change {
  border-bottom: 1px solid #9d9d9d;
  margin-top: 20px;
}

.Change .Change-info {
  height: 10px;
}

.Change .Change-info .Company-name {
  float: left;
}

.Change .Change-info .Created-at {
  float: right;
  right: 0;
  font-style: italic;
}

.Change .Change-details {
  padding-bottom: 5px;
}

.Change .Old-change {
  background-color: #eeeeee;
  margin: 0 5px 5px 0;
  padding: 2px 5px;
  text-decoration: line-through;
}

.Change .New-change {
  background-color: #ccebf5;
  padding: 2px 5px;
  margin: 0 5px 5px 0;
  display: inline-block;
}
