.badge {
  background: #0078b0;
}

.badge-in_progress {
  background: #e1a357;
}

.badge-completed {
  background: #888888;
}
