.Changes {
  border: 1px solid #9d9d9d;
  border-radius: 3px;
  padding-bottom: 20px;
}

.Changes .Content-table-last .Change ~ .no-updates,
.no-updates ~ .no-updates {
  display: none;
}
