.Login .btn-forgot-pass {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.Login .btn-forgot-pass:hover {
  color: #009ece;
}

.Login .form-group.forgot-pass {
  margin-bottom: 0;
}

.Login .logo2 {
  margin-bottom: 50px;
}

.Login .signup-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.Login .link-div {
  text-align: center;
  margin-top: 30px;
}

.Login-form {
  margin-top: 150px;
}

@media (max-width: 992px) {
  .Login-form {
    margin-top: 30px;
  }
}

@media (max-height: 769px) {
  .Sidebar .Sidebar-description {
    display: none;
  }
}
