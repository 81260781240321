.SearchResults tbody tr {
  cursor: pointer;
  height: 67px;
}

.SearchResults tbody tr:hover,
.SearchResults tbody tr.active {
  background: #f1f6f8;
}

.SearchResults .Action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchResults .Action .Position {
  flex: 0.9;
}

.SearchResults .td-radio .input-control {
  height: 0;
  margin-bottom: 0;
}

.SearchResults .td-radio .input-control .radio {
  margin-top: 0;
  margin-bottom: 0;
}

.SearchResults .td-radio {
  width: 10px;
}

.SearchResults .td-Action {
  min-width: 100px;
}

.SearchNoResults .SearchNoResults-header h4,
.SearchNoResults .SearchNoResults-header p {
  text-align: center;
  max-width: 640px;
  margin: 30px auto;
}

.search-submit,
.search-reset {
  position: absolute;
  top: 9px;
  font-size: 18px;
  color: #ccc;
}
.search-submit {
  right: 30px;
}
.search-reset {
  right: 62px;
}

.search-reset:hover {
  color: #999;
}

.DefaultSearchResults .table {
  table-layout: fixed;
  margin-bottom: 0;
}

.DefaultSearchResults .table .td-actions {
  text-align: right;
}

.DefaultSearchResults .table .td-results {
  padding: 0;
}

.DefaultSearchResults .reportsPurchaseTable {
  border-top: 0;
  border-left: 5px solid #00d2c7;
}

.DefaultSearchResults .reportsPurchaseTable .company-rows {
  position: relative;
}

.DefaultSearchResults .reportsPurchaseTable tr {
  height: auto;
}

.DefaultSearchResults .reportsPurchaseTable .th-report {
  white-space: nowrap;
}

.DefaultSearchResults .reportsPurchaseTable .th-language {
  white-space: nowrap;
}

.DefaultSearchResults .reportsPurchaseTable .th-availability {
  white-space: nowrap;
}

.DefaultSearchResults .reportsPurchaseTable .th-order {
  width: 520px;
}

.DefaultSearchResults
  .reportsPurchaseTable
  .reportsPurchaseTableInner
  tbody
  tr:last-child {
  border: 0;
}

.DefaultSearchResults .reportsPurchaseTableInner {
  table-layout: auto;
}

.DefaultSearchResults .reportsPurchaseLoader {
  height: 200px;
  position: relative;
}

/* .no-border could go in common CSS */

.no-border {
  border: 0;
}

.Search {
  max-width: 1170px;
}

.DefaultSearchResults .reportsPurchaseTableInner button {
  margin-top: 5px;
  margin-bottom: 5px;
}
