.VerifyEmail .VerifyEmail-content {
  margin-top: 150px;
}

.VerifyEmail .logo2 {
  margin-bottom: 50px;
}

.VerifyEmail .btn-resend {
  margin-top: 10px;
}

.VerifyEmail .alert {
  margin-top: 15px;
}
