.Header.Header-wide {
  width: 100%;
}

.Header .Header-nav {
  margin-top: 8px;
  display: inline-block;
}

.Header .Header-nav.navbar-nav > li {
  padding-right: 0;
}

.Header .Header-nav.navbar-nav > li > a {
  color: #005172;
  cursor: pointer;
  text-shadow: none;
}

.Header .Header-nav.navbar-nav > .active > a,
.Header .Header-nav.navbar-nav > .active > a:hover,
.Header .Header-nav.navbar-nav > .active > a:focus {
  background: none;
  box-shadow: none;
}

.Header .Header-nav .dropdown.open .dropdown-toggle,
.Header .Header-nav .dropdown.open .dropdown-toggle:focus {
  background: #eef9fd;
  box-shadow: none;
}

.Header.navbar-inverse .Header-nav .dropdown-menu {
  max-width: 320px;
  padding: 0;
  background: #ffffff;
  border: 1px solid #d7e0e4;
  border-radius: 0;
}

.Header .Header-nav .dropdown-menu .Link,
.Header #logout {
  color: #009dce;
  cursor: pointer;
}

.Header .Header-nav .dropdown-menu .Link:hover,
.Header #logout:hover {
  text-decoration: none;
  color: #00b5e6;
}

.Header .Header-nav .dropdown-menu a:hover,
.Header .Header-nav .dropdown-menu a:focus,
.Header .Header-nav .dropdown-menu div:hover,
.Header .Header-nav .dropdown-menu div:focus {
  box-shadow: none;
  outline: none;
}

.Header .Header-nav .Dropdown-header {
  background-color: #fafbfb;
  color: #003c53;
  font-size: 12px;
  line-height: 17px;
  padding: 3px 0px;
}

.Header .Header-nav .Dropdown-header:hover {
  background: #fafbfb;
}

.Header .Header-nav .Dropdown-header span {
  color: #003c53;
}

.Header .Header-nav .Header-with-link .Link {
  float: right;
}

.Header .Header-nav .Dropdown-content {
  padding: 0;
  border-bottom: 1px solid #d7e0e4;
}

.Header #logout {
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  padding: 10px 14px 3px 7px;
}

.Header .Header-nav h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.Header .Company-details h4 {
  font-size: 14px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.Header .Company-details img {
  width: 62px;
  height: 62px;
}

.Header .navbar-toggle {
  border-color: transparent;
  margin-top: 20px;
  margin-right: -15px;
}

.Header .navbar-toggle .icon-bar {
  background-color: #005172;
}

.Header .navbar-toggle:hover {
  background-color: #eef9fd;
}

.Header.navbar-inverse .navbar-collapse {
  border-color: transparent;
  text-align: left;
  background-color: #ffffff;
}

.Header .navbar-nav > li > a,
.Header.navbar-inverse .navbar-nav > li > a {
  text-align: left;
  color: #333333;
}

/* .Header .dropdown-menu li{
  border-bottom: 0;
} */

.Header .dropdown-menu li:hover,
.Header .navbar-nav > .active > a:hover,
.Header .navbar-nav > .active > a:focus,
.Header.navbar-inverse .navbar-nav > li > a:hover,
.Header.navbar-inverse .navbar-nav > li > a:focus,
.Header.navbar-inverse .navbar-nav > .open > a,
.Header.navbar-inverse .navbar-nav > .open > a:hover,
.Header.navbar-inverse .navbar-nav > .open > a:focus {
  color: #005172;
  background-color: #ffffff;
}

.Header .Hamburger-colored-circle {
  border-radius: 100%;
  background: #3e85c1;
  color: #fff;
  display: inline-block;
  line-height: 1.5em;
  font-weight: 400;
  text-align: center;
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.Header.navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}

.Header .Header-navitem {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #eee;
}

.Header .Header-nav .dropdown-menu .Link {
  font-weight: 600;
}

@media (max-width: 768px) {
  .Header.navbar-inverse
    .navbar-nav
    .open
    .dropdown-menu
    > li.Edit-account-small-header
    > a {
    color: #005172;
  }

  .Header.navbar-inverse .Header-nav .dropdown-menu {
    width: auto;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  .Header .navbar-nav .open .dropdown-menu {
    background-color: #fff;
    border: 1px solid #d7e0e4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }

  .Header.navbar-inverse .Header-nav .dropdown-menu .Link,
  .Header #logout {
    font-size: 14px;
    font-weight: 400;
    color: #005172;
  }

  .Header.navbar-inverse .dropdown-menu li {
    border-bottom: none;
    padding: 8px 0;
  }
}

.Header.navbar-inverse .navbar-nav .open .dropdown-menu-right > li > a {
  color: #333;
  padding: 0;
}

@media (min-width: 768px) {
  .Header .Header-nav.navbar-right ~ .navbar-right {
    margin-right: -60px;
  }
}

.Header.navbar > .container .navbar-brand.Navbar-brand,
.Header.navbar > .container-fluid .navbar-brand.Navbar-brand {
  margin-left: -30px;
}

.Header .navbar-header {
  margin-top: 18px;
  display: inline-block;
}

.Header .breadcrumb {
  display: inline-block;
  margin-bottom: 0;
  margin-top: -5px;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
}

.Header .breadcrumb li a {
  color: #888888;
  font-size: 17px;
}

.Header .breadcrumb > .active {
  color: #005172;
  font-size: 17px;
}

.Header .breadcrumb > li + li:before {
  content: '>';
  color: #888888;
}

.Header .navbar-header .icon-ic_hamburger {
  cursor: pointer;
  background-color: transparent;
  border-radius: 2px;
  padding: 8px 10px 8px 5px;
}

.Header .navbar-header .icon-ic_hamburger:hover {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 8px 10px 8px 5px;
}

.Header .Logo-xs {
  height: 38px;
  max-width: 100%;
}

.Header .navbar-header .navbar-collapse {
  width: 120%;
}

@media (max-width: 768px) {
  .Header.navbar-inverse .navbar-collapse {
    width: 120%;
  }

  .Header .Header-nav {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
}

@media (min-width: 768px) {
  .Header .container > .navbar-header,
  .Header .container-fluid > .navbar-header,
  .Header .container > .navbar-collapse,
  .Header .container-fluid > .navbar-collapse {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.Header .dropdown.open .caret {
  transform: rotate(180deg);
  transition: all 250ms ease;
}

.Header .dropdown .caret {
  transition: all 250ms ease;
}
