/* Claim no results template */
.ProductOptionBox {
  border: 2px solid #d7e0e4;
  padding: 20px;
  padding-bottom: 90px;
  text-align: center;
  min-height: 400px;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.ProductOptionBox .recommend {
  position: absolute;
  top: -2px;
  right: 20px;
}
.ProductOptionBox--highlighted {
  border: 2px solid #009dce;
  box-shadow: 0 2px 20px 8px rgba(0, 0, 0, 0.1);
}

.ProductOptionBox-header {
  color: #009dce;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px;
}
.ProductOptionBox-title {
  margin-top: 16px;
}

.ProductOptionBox-body {
  line-height: 24px;
  color: #005172;
}
.ProductOptionBox-button {
  bottom: 30px;
  position: absolute;
  left: 0;
  right: 0;
}

.ProductOptionBox-title-green {
  color: #00b2a9;
}

@media (min-width: 992px) and (max-width: 1035px) {
  .ProductOptionBox {
    min-height: 460px;
  }
}

@media (min-width: 1035px) and (max-width: 1303px) {
  .ProductOptionBox {
    min-height: 416px;
  }
}

@media (min-width: 1400px) and (max-width: 1457px) {
  .ProductOptionBox {
    min-height: 370px;
  }
}
/* END Claim no results template */
