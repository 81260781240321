.WizardProgress {
  margin-top: 0px;
}

.WizardProgress {
  padding: 0 0 10px 0;
}

.WizardProgress > .WizardProgress-step {
  padding: 0;
  margin: 0;
  position: relative;
}

.WizardProgress > .WizardProgress-step .WizardProgress-stepnum {
  color: #005172;
  font-size: 18px;
}

.WizardProgress > .WizardProgress-step .WizardProgress-stepnum span.number {
  font-weight: 600;
}

.WizardProgress > .WizardProgress-step .WizardProgress-info {
  color: #005172;
  font-size: 14px;
  line-height: 1.4;
  font-family: open_sansregular, Helvetica, Arial, sans-serif;
}

.WizardProgress > .WizardProgress-step > .WizardProgress-dot {
  position: absolute;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px #005172 solid;
  top: 20px;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  border-radius: 50%;
}

.WizardProgress > .WizardProgress-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 2px;
  box-shadow: none;
  margin: 20px 0;
  background: #005172;
}

.WizardProgress > .WizardProgress-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #005172;
}

.WizardProgress > .WizardProgress-step.complete > .progress > .progress-bar {
  width: 100%;
}

.WizardProgress > .WizardProgress-step.active > .progress > .progress-bar {
  width: 50%;
}

.WizardProgress
  > .WizardProgress-step:first-child.active
  > .progress
  > .progress-bar {
  width: 0%;
}

.WizardProgress
  > .WizardProgress-step:last-child.active
  > .progress
  > .progress-bar {
  width: 100%;
}

.WizardProgress > .WizardProgress-step.disabled > .WizardProgress-dot {
  /* color: #f1f6f8; */
}

.WizardProgress
  .WizardProgress-step.disabled
  .WizardProgress-dot.WizardProgress-stepnum
  span {
  color: #005172;
  position: relative;
}

.WizardProgress > .WizardProgress-step.active > .WizardProgress-dot {
  background: #005172;
}

.WizardProgress
  .WizardProgress-step.active
  .WizardProgress-dot.WizardProgress-stepnum
  span {
  color: #ffffff;
}

.WizardProgress > .WizardProgress-step.disabled > .WizardProgress-dot:after {
  opacity: 0;
}

.WizardProgress > .WizardProgress-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.WizardProgress > .WizardProgress-step:last-child > .progress {
  width: 50%;
}

.WizardProgress > .WizardProgress-step.disabled a.WizardProgress-dot {
  pointer-events: none;
}

.WizardProgress.col-wizard-1,
.WizardProgress.col-wizard-2,
.WizardProgress.col-wizard-3,
.WizardProgress.col-wizard-4,
.WizardProgress.col-wizard-5,
.WizardProgress.col-wizard-6,
.WizardProgress.col-wizard-7,
.WizardProgress.col-wizard-8,
.WizardProgress.col-wizard-9,
.WizardProgress.col-wizard-10,
.WizardProgress.col-wizard-11,
.WizardProgress.col-wizard-12 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.WizardProgress .col-wizard-1,
.WizardProgress .col-wizard-2,
.WizardProgress .col-wizard-3,
.WizardProgress .col-wizard-4,
.WizardProgress .col-wizard-5,
.WizardProgress .col-wizard-6,
.WizardProgress .col-wizard-7,
.WizardProgress .col-wizard-8,
.WizardProgress .col-wizard-9,
.WizardProgress .col-wizard-10,
.WizardProgress .col-wizard-11,
.WizardProgress .col-wizard-12 {
  float: left;
}

.WizardProgress .col-wizard-12 {
  width: 8.33333333%;
}

.WizardProgress .col-wizard-11 {
  width: 9.09090909%;
}

.WizardProgress .col-wizard-10 {
  width: 10%;
}

.WizardProgress .col-wizard-9 {
  width: 11.11111111%;
}

.WizardProgress .col-wizard-8 {
  width: 12.5%;
}

.WizardProgress .col-wizard-7 {
  width: 14.28571428%;
}

.WizardProgress .col-wizard-6 {
  width: 16.66666667%;
}

.WizardProgress .col-wizard-5 {
  width: 20%;
}

.WizardProgress .col-wizard-4 {
  width: 25%;
}

.WizardProgress .col-wizard-3 {
  width: 33.33333333%;
}

.WizardProgress .col-wizard-2 {
  width: 50%;
}

.WizardProgress .col-wizard-1 {
  width: 100%;
}
