.ForgotPassword .back-login {
  text-align: center;
}

.ForgotPassword .login-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.ForgotPassword .login-link:hover {
  color: #009ece;
}

.ForgotPassword .enter-email {
  margin-bottom: 26px;
}

.ForgotPassword .signup-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.ForgotPassword .link-div {
  text-align: center;
  margin-top: 30px;
}
