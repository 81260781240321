@font-face {
  font-family: 'fontello';
  src: url('../_fonts/fontello.eot?21981620');
  src: url('../_fonts/fontello.eot?21981620#iefix') format('embedded-opentype'),
    url('../_fonts/fontello.woff2?21981620') format('woff2'),
    url('../_fonts/fontello.woff?21981620') format('woff'),
    url('../_fonts/fontello.ttf?21981620') format('truetype'),
    url('../_fonts/fontello.svg?21981620#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?21981620#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ic_credits:before {
  content: '\e800';
} /* '' */
.icon-ic_claimed:before {
  content: '\e801';
} /* '' */
.icon-ic_close:before {
  content: '\e802';
} /* '' */
.icon-ic_company-details:before {
  content: '\e803';
} /* '' */
.icon-ic_confirmation-call:before {
  content: '\e804';
} /* '' */
.icon-ic_dbia:before {
  content: '\e805';
} /* '' */
.icon-ic_file:before {
  content: '\e806';
} /* '' */
.icon-ic_info:before {
  content: '\e807';
} /* '' */
.icon-ic_lock:before {
  content: '\e808';
} /* '' */
.icon-ic_plus:before {
  content: '\e809';
} /* '' */
.icon-ic_search:before {
  content: '\e80a';
} /* '' */
.icon-ic_talk-to-us:before {
  content: '\e80b';
} /* '' */
.icon-ic_user:before {
  content: '\e80c';
} /* '' */
.icon-ic_warning:before {
  content: '\e80d';
} /* '' */
.icon-ic_check:before {
  content: '\e80e';
} /* '' */
.icon-ic_admin-panel:before {
  content: '\e80f';
} /* '' */
.icon-ic_subscription:before {
  content: '\e810';
} /* '' */
.icon-ic_orders:before {
  content: '\e811';
} /* '' */
.icon-ic_hamburger:before {
  content: '\e818';
} /* '' */
.icon-ic_bell:before {
  content: '\e81e';
} /* '' */
.icon-ic_card:before {
  content: '\e81f';
} /* '' */
.icon-ic_eye-slash:before {
  content: '\e820';
} /* '' */
.icon-ic_eye:before {
  content: '\e821';
} /* '' */
.icon-ic_claim:before {
  content: '\f12e';
} /* '' */
.icon-ic_pdf-file:before {
  content: '\f1c1';
} /* '' */
.icon-ic_claimrequest:before {
  content: '\f1cd';
} /* '' */
