.Header {
  margin-bottom: 0;
  background-color: #ffffff;
  border-color: transparent;
  background-image: none;
  height: 70px;
  padding-bottom: 10px;
  position: fixed;
  z-index: 1001;
}

.Header.navbar-default {
  box-shadow: none;
}

.Walkthrough-resetBtn {
  display: none;
}
.Walkthrough-resetBtn.show {
  display: block;
}
