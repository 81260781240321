.SingleUniverse {
}

.SingleUniverse button {
  float: right;
}

.SingleUniverse .info-container {
  margin-top: 20px;
}

.SingleUniverse .company-container {
  border: 1px solid #9d9d9d;
  border-radius: 3px;
  padding-bottom: 20px;
}

.SingleUniverse .company-info {
  border-bottom: 1px solid #9d9d9d;
  margin-top: 20px;
}

.SingleUniverse .Content-table-last {
  padding: 0 20px;
}

.SingleUniverse .Title-table {
  background-color: #f6f6f6;
}
