.Alert {
  font-size: 14px;
  padding: 10px;
  margin-bottom: 10px;
}

.Alert.Alert-info {
  background: #3e85c1;
  color: #ffffff;
}
.Alert.Alert-pointer {
  background: #3e85c1;
  color: #ffffff;
  cursor: pointer;
}
.Alert.Alert-with-icon {
  display: flex;
  text-align: left;
  justify-content: space-between;
}
.Alert.Alert-with-icon p {
  flex-grow: 1;
  margin: 10px 0;
}
.Alert.Alert-with-icon i {
  padding: 10px;
}
