.NavigationMenu {
  background-color: #003c53;
  height: 100vh;
  padding-top: 20px;
  position: fixed;
  z-index: 1002;
}

.NavigationMenu-list {
  list-style-type: none;
}

.NavigationMenu-list > li {
}

.NavigationMenu-list > li > a,
.NavigationMenu-list > li > a:hover,
.NavigationMenu-list .dropdown-toggle.btn-default,
.NavigationMenu-list .dropdown-menu > li > a,
.NavigationMenu-list .dropdown-menu {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #d5dee3;
  text-decoration: none;
  padding: 14px 0 14px 20px;
}

.NavigationMenu-list > li > a:hover,
.NavigationMenu-list > li > a:focus,
.NavigationMenu-list .dropdown-toggle.btn-default:hover,
.NavigationMenu-list .dropdown-toggle.btn-default:focus,
.NavigationMenu-list .open > .dropdown-toggle.btn-default,
.NavigationMenu-list .open > .dropdown-toggle.btn-default:hover,
.NavigationMenu-list .open > .dropdown-toggle.btn-default:focus,
.NavigationMenu-list .dropdown-menu:hover,
.NavigationMenu-list .dropdown-menu > li > a:hover,
.NavigationMenu-list .dropdown-menu:focus,
.NavigationMenu-list .dropdown-menu > li > a:focus {
  background-color: transparent;
  color: #00d2c7;
}

.NavigationMenu-list i {
  font-size: 18px;
  margin-right: 20px;
}

.NavigationMenu-list .icon-ic_dashboard {
  font-size: 16px;
}

.NavigationMenu-list > li > a.active {
  color: #00d2c7;
}

.NavigationMenu-list .dropdown-menu,
.NavigationMenu-list .dropdown-menu > li > a {
  background-color: transparent;
  color: #d5dee3;
  border: none;
  padding: 0 0 4px 28px;
  box-shadow: none;
}

.NavigationMenu-list .dropdown-menu li,
.NavigationMenu-list .dropdown-menu li:hover {
  border-bottom: none;
  background-color: transparent;
}

.NavigationMenu-logo {
  height: 38px;
  max-width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
}

.NavigationMenu .nav > li > a > img {
  max-width: 100%;
}

.NavigationMenu .dropdown-menu > .active > a {
  background-color: transparent;
  background-image: none;
}

.wrapper {
  display: flex;
  width: 100%;
}

.NavigationMenu-list .dropdown.open .caret {
  transform: rotate(180deg);
  transition: all 250ms ease;
}

.NavigationMenu-list .dropdown .caret {
  transition: all 250ms ease;
}

.NavigationMenu .fixed-bottom {
  position: absolute;
  bottom: 50px;
  width: 100%;
  padding-right: 30px;
}

.NavigationMenu .fixed-bottom .tos {
  margin-left: 20px;
  margin-top: 15px;
}

.NavigationMenu .fixed-bottom .tos a {
  font-size: 12px;
  color: #d5dee3;
}

.NavigationMenu .fixed-bottom .tos a:hover {
  color: #00d2c7;
}

.NavigationMenu .fixed-bottom .tos span.middot {
  color: #d5dee3;
  margin: 0 7px;
}

@media (max-height: 280px) {
  .NavigationMenu .fixed-bottom {
    display: none;
  }
}
