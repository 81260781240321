.Register .countries-list {
  margin-bottom: 20px;
}

.Register .btn-group > .btn:first-child {
  margin-left: -10px;
}

.Register .signup-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.Register .link-div {
  text-align: center;
  margin-top: 30px;
}

.Register .phone-extension {
  white-space: nowrap;
}
