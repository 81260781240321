.report-modal .modal-footer {
  padding-top: 20px;
}

.wrapper.reportWrapper {
  flex-direction: column;
}

.wrapper.reportWrapper .contentWrapper {
  width: 88%;
}

.wrapper.reportWrapper .contentWrapper a.btn {
  color: inherit;
  line-height: inherit;
}

.wrapper.reportWrapper .contentWrapper [class^='icon-']:before {
  font-family: 'icomoon';
}

@media print {
  .modal-open #drift-widget-container,
  .modal-open #root,
  .modal-open .report-modal .modal-dialog .modal-header,
  .modal-open .report-modal .modal-dialog .modal-footer {
    display: none;
  }

  .modal-open .report-modal .modal-dialog,
  .modal-open .wrapper.reportWrapper .contentWrapper {
    width: 100%;
    margin: 0 auto;
  }

  .modal-open .report-modal .modal-dialog .modal-content {
    border: 0;
  }

  .modal-open .report-modal .modal-dialog .modal-content .modal-body {
    padding: 0;
  }

  .modal-open .modal.report-modal {
    position: absolute;
    overflow: visible;
  }
}
