.CompanyProfile .gmap-embed {
  margin: 30px -20px -25px -20px;
}

.CompanyProfile .panel {
  margin: 0 0 20px 0;
}

.CompanyProfile ul {
  padding-left: 18px;
}

.CompanyProfile .inpage-nav {
  position: sticky;
  top: 70px;
}

.CompanyProfile .inpage-nav ul {
  list-style: none;
  padding-left: 0;
}

.CompanyProfile .inpage-nav ul li {
  font-weight: 600;
  border-left: 5px solid #dee4ed;
  padding: 10px;
  cursor: pointer;
}

.CompanyProfile .inpage-nav ul li.active-li {
  border-color: #00d2c7;
  color: #00d2c7;
}

.CompanyProfile .panel-content {
  overflow: hidden;
}
