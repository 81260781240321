.Wizard.GetDunsNumber .WizardPage {
  position: relative;
  min-height: 300px;
}

.Wizard.GetDunsNumber .WizardPage .GetDunsNumberForm {
  margin: 60px auto;
  max-width: 600px;
}

.Wizard.GetDunsNumber .WizardPage > .info {
  margin-top: 60px;
  max-width: 760px;
}

.Wizard.GetDunsNumber .WizardPage h1 {
  color: #005172;
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
}

.Wizard.GetDunsNumber .WizardPage h2 {
  margin: 50px 0 10px 0;
  color: #005172;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.Wizard.GetDunsNumber .WizardPage p {
  font-size: 16px;
  line-height: 20px;
}
