@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.57a07cc7.eot);
  src: url(/static/media/fontello.57a07cc7.eot#iefix) format('embedded-opentype'),
    url(/static/media/fontello.6b5eeca4.woff2) format('woff2'),
    url(/static/media/fontello.ff272663.woff) format('woff'),
    url(/static/media/fontello.855273ee.ttf) format('truetype'),
    url(/static/media/fontello.e621dbc2.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?21981620#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ic_credits:before {
  content: '\E800';
} /* '' */
.icon-ic_claimed:before {
  content: '\E801';
} /* '' */
.icon-ic_close:before {
  content: '\E802';
} /* '' */
.icon-ic_company-details:before {
  content: '\E803';
} /* '' */
.icon-ic_confirmation-call:before {
  content: '\E804';
} /* '' */
.icon-ic_dbia:before {
  content: '\E805';
} /* '' */
.icon-ic_file:before {
  content: '\E806';
} /* '' */
.icon-ic_info:before {
  content: '\E807';
} /* '' */
.icon-ic_lock:before {
  content: '\E808';
} /* '' */
.icon-ic_plus:before {
  content: '\E809';
} /* '' */
.icon-ic_search:before {
  content: '\E80A';
} /* '' */
.icon-ic_talk-to-us:before {
  content: '\E80B';
} /* '' */
.icon-ic_user:before {
  content: '\E80C';
} /* '' */
.icon-ic_warning:before {
  content: '\E80D';
} /* '' */
.icon-ic_check:before {
  content: '\E80E';
} /* '' */
.icon-ic_admin-panel:before {
  content: '\E80F';
} /* '' */
.icon-ic_subscription:before {
  content: '\E810';
} /* '' */
.icon-ic_orders:before {
  content: '\E811';
} /* '' */
.icon-ic_hamburger:before {
  content: '\E818';
} /* '' */
.icon-ic_bell:before {
  content: '\E81E';
} /* '' */
.icon-ic_card:before {
  content: '\E81F';
} /* '' */
.icon-ic_eye-slash:before {
  content: '\E820';
} /* '' */
.icon-ic_eye:before {
  content: '\E821';
} /* '' */
.icon-ic_claim:before {
  content: '\F12E';
} /* '' */
.icon-ic_pdf-file:before {
  content: '\F1C1';
} /* '' */
.icon-ic_claimrequest:before {
  content: '\F1CD';
} /* '' */

var {
  font-style: inherit;
}

.nav-hr {
  width: 85%;
  border-top: 1px solid #022a3c;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  font-weight: 600;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
}

strong {
  font-weight: 600;
}

a:hover {
  text-decoration: none;
}

.mtop-30 {
  margin-top: 30px;
}

.mtop-50 {
  margin-top: 50px;
}

.mbot-20 {
  margin-bottom: 20px;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.Title-table {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  padding: 16px 20px;
  margin: 0 0 10px 0;
}

.Colored-circle {
  border-radius: 50px;
  background: #3e85c1;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  width: 62px;
  height: 62px;
  font-size: 25px;
}

.Colored-circle:hover {
  background: #3e85c1;
}

.light-text {
  color: #777;
}

/* .SearchableDropdown */
.form-group .SearchableDropdown__control {
  width: 100%;
  border-color: #d7e0e4;
  border-radius: 2px;
  box-shadow: none;
  font-size: 14px;
  color: #555;
}

.SearchableDropdown__control.SearchableDropdown__control--is-focused {
  border-color: #66afe9;
  border-radius: 0 !important;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.SearchableDropdown__placeholder {
  color: #999999 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}

.SearchableDropdown__menu {
  border-radius: 0 !important;
}

.SearchableDropdown__option {
  color: #333333 !important;
}

.SearchableDropdown__option.SearchableDropdown__option--is-focused {
  background-color: #f2f2f2;
  color: #333333;
}

.SearchableDropdown__option.SearchableDropdown__option--is-selected {
  background-color: #e4e4e4;
  color: #333333;
}
/* .SearchableDropdown END */

.SearchableDropdown__input input,
.StateDropdown__input input,
.CreatableEmail input {
  height: 31px;
}

.panel {
  border: 1px solid #d7e0e4;
  box-shadow: none;
  padding: 0;
  border-radius: 3px;
}

.panel .panel-heading {
  border-bottom: 1px solid #d7e0e4;
}

.panel .panel-heading h5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

button + button {
  margin-left: 10px;
}

.table-responsive {
  overflow-x: visible;
  overflow-x: initial;
}

.call-verification-step {
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
  max-width: 640px;
}

.call-verification-step .svg-confirmation-call {
  margin-top: 100px;
}

.delivery-step {
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
  max-width: 760px;
}

/* Badges */

.badges .badge {
  margin-bottom: 5px;
}

.badges .badge:not(:first-of-type) {
  margin-left: 10px;
}

.badge {
  border-radius: 20px;
  padding: 6px 12px;
}

.badges.badges-5 {
  display: flex;
}

.badges.badges-5 .badge {
  padding: 12px 0;
  width: 20%;
  font-size: 20px;
  border-radius: 2px;
}

.badge.danger {
  background: #f79851;
}

.badge.active {
  background: #00d2c7;
}

.badge.success {
  background: #009ece;
}

.badge.inactive {
  background: #cf3154;
}

.Header {
  margin-bottom: 0;
  background-color: #ffffff;
  border-color: transparent;
  background-image: none;
  height: 70px;
  padding-bottom: 10px;
  position: fixed;
  z-index: 1001;
}

.Header.navbar-default {
  box-shadow: none;
}

.Walkthrough-resetBtn {
  display: none;
}
.Walkthrough-resetBtn.show {
  display: block;
}

.PublicHeader .container {
  width: 100%;
}

.PublicHeader .Public-navbar > li {
  float: left;
}

.PublicHeader .navbar-text {
  margin-right: 30px;
}

.PublicHeader .Public-navbar li > a#language-nav-dropdown {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .PublicHeader .Public-navbar {
    box-shadow: none;
    margin-right: 20px;
  }
}

.ChangePassword .ChangePassword-form {
  margin-top: 150px;
}

.Header.Header-wide {
  width: 100%;
}

.Header .Header-nav {
  margin-top: 8px;
  display: inline-block;
}

.Header .Header-nav.navbar-nav > li {
  padding-right: 0;
}

.Header .Header-nav.navbar-nav > li > a {
  color: #005172;
  cursor: pointer;
  text-shadow: none;
}

.Header .Header-nav.navbar-nav > .active > a,
.Header .Header-nav.navbar-nav > .active > a:hover,
.Header .Header-nav.navbar-nav > .active > a:focus {
  background: none;
  box-shadow: none;
}

.Header .Header-nav .dropdown.open .dropdown-toggle,
.Header .Header-nav .dropdown.open .dropdown-toggle:focus {
  background: #eef9fd;
  box-shadow: none;
}

.Header.navbar-inverse .Header-nav .dropdown-menu {
  max-width: 320px;
  padding: 0;
  background: #ffffff;
  border: 1px solid #d7e0e4;
  border-radius: 0;
}

.Header .Header-nav .dropdown-menu .Link,
.Header #logout {
  color: #009dce;
  cursor: pointer;
}

.Header .Header-nav .dropdown-menu .Link:hover,
.Header #logout:hover {
  text-decoration: none;
  color: #00b5e6;
}

.Header .Header-nav .dropdown-menu a:hover,
.Header .Header-nav .dropdown-menu a:focus,
.Header .Header-nav .dropdown-menu div:hover,
.Header .Header-nav .dropdown-menu div:focus {
  box-shadow: none;
  outline: none;
}

.Header .Header-nav .Dropdown-header {
  background-color: #fafbfb;
  color: #003c53;
  font-size: 12px;
  line-height: 17px;
  padding: 3px 0px;
}

.Header .Header-nav .Dropdown-header:hover {
  background: #fafbfb;
}

.Header .Header-nav .Dropdown-header span {
  color: #003c53;
}

.Header .Header-nav .Header-with-link .Link {
  float: right;
}

.Header .Header-nav .Dropdown-content {
  padding: 0;
  border-bottom: 1px solid #d7e0e4;
}

.Header #logout {
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  padding: 10px 14px 3px 7px;
}

.Header .Header-nav h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.Header .Company-details h4 {
  font-size: 14px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.Header .Company-details img {
  width: 62px;
  height: 62px;
}

.Header .navbar-toggle {
  border-color: transparent;
  margin-top: 20px;
  margin-right: -15px;
}

.Header .navbar-toggle .icon-bar {
  background-color: #005172;
}

.Header .navbar-toggle:hover {
  background-color: #eef9fd;
}

.Header.navbar-inverse .navbar-collapse {
  border-color: transparent;
  text-align: left;
  background-color: #ffffff;
}

.Header .navbar-nav > li > a,
.Header.navbar-inverse .navbar-nav > li > a {
  text-align: left;
  color: #333333;
}

/* .Header .dropdown-menu li{
  border-bottom: 0;
} */

.Header .dropdown-menu li:hover,
.Header .navbar-nav > .active > a:hover,
.Header .navbar-nav > .active > a:focus,
.Header.navbar-inverse .navbar-nav > li > a:hover,
.Header.navbar-inverse .navbar-nav > li > a:focus,
.Header.navbar-inverse .navbar-nav > .open > a,
.Header.navbar-inverse .navbar-nav > .open > a:hover,
.Header.navbar-inverse .navbar-nav > .open > a:focus {
  color: #005172;
  background-color: #ffffff;
}

.Header .Hamburger-colored-circle {
  border-radius: 100%;
  background: #3e85c1;
  color: #fff;
  display: inline-block;
  line-height: 1.5em;
  font-weight: 400;
  text-align: center;
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.Header.navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}

.Header .Header-navitem {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #eee;
}

.Header .Header-nav .dropdown-menu .Link {
  font-weight: 600;
}

@media (max-width: 768px) {
  .Header.navbar-inverse
    .navbar-nav
    .open
    .dropdown-menu
    > li.Edit-account-small-header
    > a {
    color: #005172;
  }

  .Header.navbar-inverse .Header-nav .dropdown-menu {
    width: auto;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  .Header .navbar-nav .open .dropdown-menu {
    background-color: #fff;
    border: 1px solid #d7e0e4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }

  .Header.navbar-inverse .Header-nav .dropdown-menu .Link,
  .Header #logout {
    font-size: 14px;
    font-weight: 400;
    color: #005172;
  }

  .Header.navbar-inverse .dropdown-menu li {
    border-bottom: none;
    padding: 8px 0;
  }
}

.Header.navbar-inverse .navbar-nav .open .dropdown-menu-right > li > a {
  color: #333;
  padding: 0;
}

@media (min-width: 768px) {
  .Header .Header-nav.navbar-right ~ .navbar-right {
    margin-right: -60px;
  }
}

.Header.navbar > .container .navbar-brand.Navbar-brand,
.Header.navbar > .container-fluid .navbar-brand.Navbar-brand {
  margin-left: -30px;
}

.Header .navbar-header {
  margin-top: 18px;
  display: inline-block;
}

.Header .breadcrumb {
  display: inline-block;
  margin-bottom: 0;
  margin-top: -5px;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
}

.Header .breadcrumb li a {
  color: #888888;
  font-size: 17px;
}

.Header .breadcrumb > .active {
  color: #005172;
  font-size: 17px;
}

.Header .breadcrumb > li + li:before {
  content: '>';
  color: #888888;
}

.Header .navbar-header .icon-ic_hamburger {
  cursor: pointer;
  background-color: transparent;
  border-radius: 2px;
  padding: 8px 10px 8px 5px;
}

.Header .navbar-header .icon-ic_hamburger:hover {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 8px 10px 8px 5px;
}

.Header .Logo-xs {
  height: 38px;
  max-width: 100%;
}

.Header .navbar-header .navbar-collapse {
  width: 120%;
}

@media (max-width: 768px) {
  .Header.navbar-inverse .navbar-collapse {
    width: 120%;
  }

  .Header .Header-nav {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
}

@media (min-width: 768px) {
  .Header .container > .navbar-header,
  .Header .container-fluid > .navbar-header,
  .Header .container > .navbar-collapse,
  .Header .container-fluid > .navbar-collapse {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.Header .dropdown.open .caret {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.Header .dropdown .caret {
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.NavigationMenu {
  background-color: #003c53;
  height: 100vh;
  padding-top: 20px;
  position: fixed;
  z-index: 1002;
}

.NavigationMenu-list {
  list-style-type: none;
}

.NavigationMenu-list > li {
}

.NavigationMenu-list > li > a,
.NavigationMenu-list > li > a:hover,
.NavigationMenu-list .dropdown-toggle.btn-default,
.NavigationMenu-list .dropdown-menu > li > a,
.NavigationMenu-list .dropdown-menu {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #d5dee3;
  text-decoration: none;
  padding: 14px 0 14px 20px;
}

.NavigationMenu-list > li > a:hover,
.NavigationMenu-list > li > a:focus,
.NavigationMenu-list .dropdown-toggle.btn-default:hover,
.NavigationMenu-list .dropdown-toggle.btn-default:focus,
.NavigationMenu-list .open > .dropdown-toggle.btn-default,
.NavigationMenu-list .open > .dropdown-toggle.btn-default:hover,
.NavigationMenu-list .open > .dropdown-toggle.btn-default:focus,
.NavigationMenu-list .dropdown-menu:hover,
.NavigationMenu-list .dropdown-menu > li > a:hover,
.NavigationMenu-list .dropdown-menu:focus,
.NavigationMenu-list .dropdown-menu > li > a:focus {
  background-color: transparent;
  color: #00d2c7;
}

.NavigationMenu-list i {
  font-size: 18px;
  margin-right: 20px;
}

.NavigationMenu-list .icon-ic_dashboard {
  font-size: 16px;
}

.NavigationMenu-list > li > a.active {
  color: #00d2c7;
}

.NavigationMenu-list .dropdown-menu,
.NavigationMenu-list .dropdown-menu > li > a {
  background-color: transparent;
  color: #d5dee3;
  border: none;
  padding: 0 0 4px 28px;
  box-shadow: none;
}

.NavigationMenu-list .dropdown-menu li,
.NavigationMenu-list .dropdown-menu li:hover {
  border-bottom: none;
  background-color: transparent;
}

.NavigationMenu-logo {
  height: 38px;
  max-width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
}

.NavigationMenu .nav > li > a > img {
  max-width: 100%;
}

.NavigationMenu .dropdown-menu > .active > a {
  background-color: transparent;
  background-image: none;
}

.wrapper {
  display: flex;
  width: 100%;
}

.NavigationMenu-list .dropdown.open .caret {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.NavigationMenu-list .dropdown .caret {
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.NavigationMenu .fixed-bottom {
  position: absolute;
  bottom: 50px;
  width: 100%;
  padding-right: 30px;
}

.NavigationMenu .fixed-bottom .tos {
  margin-left: 20px;
  margin-top: 15px;
}

.NavigationMenu .fixed-bottom .tos a {
  font-size: 12px;
  color: #d5dee3;
}

.NavigationMenu .fixed-bottom .tos a:hover {
  color: #00d2c7;
}

.NavigationMenu .fixed-bottom .tos span.middot {
  color: #d5dee3;
  margin: 0 7px;
}

@media (max-height: 280px) {
  .NavigationMenu .fixed-bottom {
    display: none;
  }
}

.AppWrapper-content {
  margin-top: 70px;
}

.Navigation-animated {
  -webkit-transition: left 0.3s;
  transition: left 0.3s;
}

.Container-animated {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.Sidebar-hidden {
  left: -25%;
}

.Sidebar-visible {
  left: 0;
}

.report-modal .modal-footer {
  padding-top: 20px;
}

.wrapper.reportWrapper {
  flex-direction: column;
}

.wrapper.reportWrapper .contentWrapper {
  width: 88%;
}

.wrapper.reportWrapper .contentWrapper a.btn {
  color: inherit;
  line-height: inherit;
}

.wrapper.reportWrapper .contentWrapper [class^='icon-']:before {
  font-family: 'icomoon';
}

@media print {
  .modal-open #drift-widget-container,
  .modal-open #root,
  .modal-open .report-modal .modal-dialog .modal-header,
  .modal-open .report-modal .modal-dialog .modal-footer {
    display: none;
  }

  .modal-open .report-modal .modal-dialog,
  .modal-open .wrapper.reportWrapper .contentWrapper {
    width: 100%;
    margin: 0 auto;
  }

  .modal-open .report-modal .modal-dialog .modal-content {
    border: 0;
  }

  .modal-open .report-modal .modal-dialog .modal-content .modal-body {
    padding: 0;
  }

  .modal-open .modal.report-modal {
    position: absolute;
    overflow: visible;
  }
}

.Contact {
  background-color: #f1f6f8;
}

.Contact .Contact-h3 {
  font-size: 16px;
  color: #005172;
  font-weight: 600;
}

.Contact .Contact-h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}

.Contact .Title-paragraph {
  margin-bottom: 30px;
  font-weight: 600;
  color: #005172;
  font-size: 16px;
}

.Contact .countries-list {
  margin-bottom: 20px;
}

.Contact .Contact-hr {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #d7e0e4;
}

.Contact .Chat-button:hover,
.Contact .Chat-button:active {
  color: #ffffff;
  background-color: #0088b1;
}

.Contact .Chat-button:disabled {
  color: #ffffff;
  background-color: #b1d8e6;
}

.Contact .Contact-form {
  background-color: #ffffff;
  margin-top: 150px;
}

.Contact .logo2 {
  margin-top: 40px;
}

.Contact .row {
  background-color: #ffffff;
}

@media (max-width: 992px) {
  .Contact .Contact-form {
    margin-top: 40px;
  }
}

.Contact .back-login {
  text-align: center;
  margin-top: 30px;
}

.Contact .login-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.ForgotPassword .back-login {
  text-align: center;
}

.ForgotPassword .login-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.ForgotPassword .login-link:hover {
  color: #009ece;
}

.ForgotPassword .enter-email {
  margin-bottom: 26px;
}

.ForgotPassword .signup-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.ForgotPassword .link-div {
  text-align: center;
  margin-top: 30px;
}

.Alert {
  font-size: 14px;
  padding: 10px;
  margin-bottom: 10px;
}

.Alert.Alert-info {
  background: #3e85c1;
  color: #ffffff;
}
.Alert.Alert-pointer {
  background: #3e85c1;
  color: #ffffff;
  cursor: pointer;
}
.Alert.Alert-with-icon {
  display: flex;
  text-align: left;
  justify-content: space-between;
}
.Alert.Alert-with-icon p {
  flex-grow: 1;
  margin: 10px 0;
}
.Alert.Alert-with-icon i {
  padding: 10px;
}

/* Claim no results template */
.ProductOptionBox {
  border: 2px solid #d7e0e4;
  padding: 20px;
  padding-bottom: 90px;
  text-align: center;
  min-height: 400px;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.ProductOptionBox .recommend {
  position: absolute;
  top: -2px;
  right: 20px;
}
.ProductOptionBox--highlighted {
  border: 2px solid #009dce;
  box-shadow: 0 2px 20px 8px rgba(0, 0, 0, 0.1);
}

.ProductOptionBox-header {
  color: #009dce;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px;
}
.ProductOptionBox-title {
  margin-top: 16px;
}

.ProductOptionBox-body {
  line-height: 24px;
  color: #005172;
}
.ProductOptionBox-button {
  bottom: 30px;
  position: absolute;
  left: 0;
  right: 0;
}

.ProductOptionBox-title-green {
  color: #00b2a9;
}

@media (min-width: 992px) and (max-width: 1035px) {
  .ProductOptionBox {
    min-height: 460px;
  }
}

@media (min-width: 1035px) and (max-width: 1303px) {
  .ProductOptionBox {
    min-height: 416px;
  }
}

@media (min-width: 1400px) and (max-width: 1457px) {
  .ProductOptionBox {
    min-height: 370px;
  }
}
/* END Claim no results template */


.PageNotFound {
  display: flex;
  align-items: center;
  height: calc(100vh - 200px);
  justify-content: center;
  flex-direction: column;
}

.PageNotFound > img,
.PageNotFound > p {
  margin-bottom: 30px;
}

.PageNotFound > p {
  font-size: 30px;
  font-weight: 600;
  color: #005172;
}

.PageNotFound > img {
  margin-top: 50px;
}

@media (max-width: 1290px) and (min-width: 601px) {
  .PageNotFound > img {
    width: 350px;
    margin-top: 120px;
  }

  .PageNotFound > p {
    font-size: 26px;
  }
}

@media (max-width: 600px) and (min-width: 491px) {
  .PageNotFound > img {
    width: 350px;
    margin-top: 50px;
  }

  .PageNotFound > p {
    font-size: 26px;
  }
}

@media (max-width: 490px) {
  .PageNotFound > img {
    width: 300px;
    margin-top: 0;
  }

  .PageNotFound > p {
    font-size: 26px;
  }
}

.SearchResults tbody tr {
  cursor: pointer;
  height: 67px;
}

.SearchResults tbody tr:hover,
.SearchResults tbody tr.active {
  background: #f1f6f8;
}

.SearchResults .Action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchResults .Action .Position {
  flex: 0.9 1;
}

.SearchResults .td-radio .input-control {
  height: 0;
  margin-bottom: 0;
}

.SearchResults .td-radio .input-control .radio {
  margin-top: 0;
  margin-bottom: 0;
}

.SearchResults .td-radio {
  width: 10px;
}

.SearchResults .td-Action {
  min-width: 100px;
}

.SearchNoResults .SearchNoResults-header h4,
.SearchNoResults .SearchNoResults-header p {
  text-align: center;
  max-width: 640px;
  margin: 30px auto;
}

.search-submit,
.search-reset {
  position: absolute;
  top: 9px;
  font-size: 18px;
  color: #ccc;
}
.search-submit {
  right: 30px;
}
.search-reset {
  right: 62px;
}

.search-reset:hover {
  color: #999;
}

.DefaultSearchResults .table {
  table-layout: fixed;
  margin-bottom: 0;
}

.DefaultSearchResults .table .td-actions {
  text-align: right;
}

.DefaultSearchResults .table .td-results {
  padding: 0;
}

.DefaultSearchResults .reportsPurchaseTable {
  border-top: 0;
  border-left: 5px solid #00d2c7;
}

.DefaultSearchResults .reportsPurchaseTable .company-rows {
  position: relative;
}

.DefaultSearchResults .reportsPurchaseTable tr {
  height: auto;
}

.DefaultSearchResults .reportsPurchaseTable .th-report {
  white-space: nowrap;
}

.DefaultSearchResults .reportsPurchaseTable .th-language {
  white-space: nowrap;
}

.DefaultSearchResults .reportsPurchaseTable .th-availability {
  white-space: nowrap;
}

.DefaultSearchResults .reportsPurchaseTable .th-order {
  width: 520px;
}

.DefaultSearchResults
  .reportsPurchaseTable
  .reportsPurchaseTableInner
  tbody
  tr:last-child {
  border: 0;
}

.DefaultSearchResults .reportsPurchaseTableInner {
  table-layout: auto;
}

.DefaultSearchResults .reportsPurchaseLoader {
  height: 200px;
  position: relative;
}

/* .no-border could go in common CSS */

.no-border {
  border: 0;
}

.Search {
  max-width: 1170px;
}

.DefaultSearchResults .reportsPurchaseTableInner button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.Modal-screen .Close-modal-icon,
.Modal-confirm .Close-modal-icon {
  margin-top: -23px;
  cursor: pointer;
  color: #005172;
}

.Modal-screen .ModalTitle {
  color: #005172;
}

.Modal-screen .modal-footer {
  border-top: none;
  padding: 0 15px 20px 15px;
}

.Modal-screen .Shareholders-list,
.Modal-screen .AddShareholderRow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Modal-screen .Shareholders-list p {
  margin-bottom: 15px;
  font-weight: 600;
}
.Modal-screen .Info-text p {
  line-height: 1.6;
}
.Modal-screen .Info-text p:first-child {
  margin-bottom: 30px;
}

.Modal-screen .Btn-add-shareholder,
.Modal-screen .Btn-add-shareholder:hover,
.Modal-screen .Btn-add-shareholder:focus {
  background-color: #f6f7f9;
  border: 1px solid #d7e0e4;
  border-radius: 0;
  min-width: 170px;
  height: 40px;
  padding: 0 20px 0 20px;
  color: #009dce;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.Modal-screen .Btn-add-shareholder:hover {
  color: #0088b1;
}

.Modal-screen .Btn-add-shareholder::before {
  margin-right: 10px;
  font-size: 16px;
}

.Modal-screen .AddShareholder-btn-delete {
  color: #888888;
  line-height: 4;
  cursor: pointer;
  margin: 0 16px;
}

.Modal-screen .AddShareholder-input {
  width: 90%;
  display: inline-block;
}

.Modal-screen .AddShareholderRow i {
  margin: 0 0 0 16px;
}

@media (min-width: 992px) {
  .Modal-screen .modal-dialog {
    width: 500px;
  }
}

.Modal-screen .modal-content {
  border-radius: 0;
}

.Modal-screen .Square-badge {
  margin: 0;
}

.Modal-confirm .DbiaConfirmModal-btn-cancel {
  margin-right: 10px;
}



.Login .btn-forgot-pass {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.Login .btn-forgot-pass:hover {
  color: #009ece;
}

.Login .form-group.forgot-pass {
  margin-bottom: 0;
}

.Login .logo2 {
  margin-bottom: 50px;
}

.Login .signup-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.Login .link-div {
  text-align: center;
  margin-top: 30px;
}

.Login-form {
  margin-top: 150px;
}

@media (max-width: 992px) {
  .Login-form {
    margin-top: 30px;
  }
}

@media (max-height: 769px) {
  .Sidebar .Sidebar-description {
    display: none;
  }
}

.badge {
  background: #0078b0;
}

.badge-in_progress {
  background: #e1a357;
}

.badge-completed {
  background: #888888;
}

.User-settings,
.Company-settings {
  padding: 7px 20px;
}

.User-settings .Link,
.Company-settings .Link {
  float: right;
}

.User-image {
  width: 62px;
  height: 62px;
  border-radius: 50px;
}

.AppWrapper-content .User-image,
.AppWrapper-content .Colored-circle {
  width: 100px;
  height: 100px;
}

.User-details,
.Company-details {
  margin-left: 15px;
  overflow: hidden;
  width: 100%;
}

.User-details h4 {
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.User-details p,
.Company-details p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.Register .countries-list {
  margin-bottom: 20px;
}

.Register .btn-group > .btn:first-child {
  margin-left: -10px;
}

.Register .signup-link {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.Register .link-div {
  text-align: center;
  margin-top: 30px;
}

.Register .phone-extension {
  white-space: nowrap;
}

.VerifyEmail .VerifyEmail-content {
  margin-top: 150px;
}

.VerifyEmail .logo2 {
  margin-bottom: 50px;
}

.VerifyEmail .btn-resend {
  margin-top: 10px;
}

.VerifyEmail .alert {
  margin-top: 15px;
}

.Profile .LanguageDropdown {
  max-width: 100%;
}
.Profile .LanguageDropdown {
  margin-top: 10px;
}

.Profile,
.Company,
.Password {
  margin: 20px 10px 20px 10px;
  border: 1px solid #d7e0e4;
}
.Profile label,
.Company label,
.Password label,
.Password button[type='submit'],
.Company button[type='submit'],
.Profile button[type='submit'],
.Password .alert,
.Company .alert,
.Profile .alert {
  display: block;
  margin-top: 15px;
}
.Password input,
.Company input,
.Profile input,
.Password .alert,
.Company .alert,
.Profile .alert {
  max-width: 100%;
}
.Password button,
.Company button,
.Profile button {
  margin-top: 26px;
}
@media (max-width: 768px) {
  .Password .col-xs-12,
  .Company .col-xs-12,
  .Profile .col-xs-12 {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.Profile .Content-table-last,
.Company .Content-table-last,
.Password .Content-table-last {
  padding: 6px 20px 20px 20px;
}
.Profile .Image-container,
.Company .Image-container {
  display: inline-block;
  border-radius: 100px;
  position: relative;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.Company .Image-overlay,
.Profile .Image-overlay {
  position: absolute;
  opacity: 0;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.2);
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Profile .Image-overlay .Overlay-text,
.Company .Image-overlay .Overlay-text {
  color: #ffffff;
}
.Profile .Image-container:hover .Image-overlay,
.Company .Image-container:hover .Image-overlay {
  opacity: 1;
}

.WizardProgress {
  margin-top: 0px;
}

.WizardProgress {
  padding: 0 0 10px 0;
}

.WizardProgress > .WizardProgress-step {
  padding: 0;
  margin: 0;
  position: relative;
}

.WizardProgress > .WizardProgress-step .WizardProgress-stepnum {
  color: #005172;
  font-size: 18px;
}

.WizardProgress > .WizardProgress-step .WizardProgress-stepnum span.number {
  font-weight: 600;
}

.WizardProgress > .WizardProgress-step .WizardProgress-info {
  color: #005172;
  font-size: 14px;
  line-height: 1.4;
  font-family: open_sansregular, Helvetica, Arial, sans-serif;
}

.WizardProgress > .WizardProgress-step > .WizardProgress-dot {
  position: absolute;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px #005172 solid;
  top: 20px;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  border-radius: 50%;
}

.WizardProgress > .WizardProgress-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 2px;
  box-shadow: none;
  margin: 20px 0;
  background: #005172;
}

.WizardProgress > .WizardProgress-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #005172;
}

.WizardProgress > .WizardProgress-step.complete > .progress > .progress-bar {
  width: 100%;
}

.WizardProgress > .WizardProgress-step.active > .progress > .progress-bar {
  width: 50%;
}

.WizardProgress
  > .WizardProgress-step:first-child.active
  > .progress
  > .progress-bar {
  width: 0%;
}

.WizardProgress
  > .WizardProgress-step:last-child.active
  > .progress
  > .progress-bar {
  width: 100%;
}

.WizardProgress > .WizardProgress-step.disabled > .WizardProgress-dot {
  /* color: #f1f6f8; */
}

.WizardProgress
  .WizardProgress-step.disabled
  .WizardProgress-dot.WizardProgress-stepnum
  span {
  color: #005172;
  position: relative;
}

.WizardProgress > .WizardProgress-step.active > .WizardProgress-dot {
  background: #005172;
}

.WizardProgress
  .WizardProgress-step.active
  .WizardProgress-dot.WizardProgress-stepnum
  span {
  color: #ffffff;
}

.WizardProgress > .WizardProgress-step.disabled > .WizardProgress-dot:after {
  opacity: 0;
}

.WizardProgress > .WizardProgress-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.WizardProgress > .WizardProgress-step:last-child > .progress {
  width: 50%;
}

.WizardProgress > .WizardProgress-step.disabled a.WizardProgress-dot {
  pointer-events: none;
}

.WizardProgress.col-wizard-1,
.WizardProgress.col-wizard-2,
.WizardProgress.col-wizard-3,
.WizardProgress.col-wizard-4,
.WizardProgress.col-wizard-5,
.WizardProgress.col-wizard-6,
.WizardProgress.col-wizard-7,
.WizardProgress.col-wizard-8,
.WizardProgress.col-wizard-9,
.WizardProgress.col-wizard-10,
.WizardProgress.col-wizard-11,
.WizardProgress.col-wizard-12 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.WizardProgress .col-wizard-1,
.WizardProgress .col-wizard-2,
.WizardProgress .col-wizard-3,
.WizardProgress .col-wizard-4,
.WizardProgress .col-wizard-5,
.WizardProgress .col-wizard-6,
.WizardProgress .col-wizard-7,
.WizardProgress .col-wizard-8,
.WizardProgress .col-wizard-9,
.WizardProgress .col-wizard-10,
.WizardProgress .col-wizard-11,
.WizardProgress .col-wizard-12 {
  float: left;
}

.WizardProgress .col-wizard-12 {
  width: 8.33333333%;
}

.WizardProgress .col-wizard-11 {
  width: 9.09090909%;
}

.WizardProgress .col-wizard-10 {
  width: 10%;
}

.WizardProgress .col-wizard-9 {
  width: 11.11111111%;
}

.WizardProgress .col-wizard-8 {
  width: 12.5%;
}

.WizardProgress .col-wizard-7 {
  width: 14.28571428%;
}

.WizardProgress .col-wizard-6 {
  width: 16.66666667%;
}

.WizardProgress .col-wizard-5 {
  width: 20%;
}

.WizardProgress .col-wizard-4 {
  width: 25%;
}

.WizardProgress .col-wizard-3 {
  width: 33.33333333%;
}

.WizardProgress .col-wizard-2 {
  width: 50%;
}

.WizardProgress .col-wizard-1 {
  width: 100%;
}

.delivery-step .delivery-step-certificate {
  background-repeat: no-repeat;
  background-position: center;
  min-height: 261px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delivery-step .delivery-step-heading {
  font-weight: 600;
  font-size: 32px;
  color: #00b2a9;
}

.delivery-step .delivery-step-subheading {
  font-weight: 600;
  font-size: 24px;
  color: #0078b0;
}

.delivery-step .delivery-step-text {
  font-weight: 600;
}

.Wizard.GetDunsNumber .WizardPage {
  position: relative;
  min-height: 300px;
}

.Wizard.GetDunsNumber .WizardPage .GetDunsNumberForm {
  margin: 60px auto;
  max-width: 600px;
}

.Wizard.GetDunsNumber .WizardPage > .info {
  margin-top: 60px;
  max-width: 760px;
}

.Wizard.GetDunsNumber .WizardPage h1 {
  color: #005172;
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
}

.Wizard.GetDunsNumber .WizardPage h2 {
  margin: 50px 0 10px 0;
  color: #005172;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.Wizard.GetDunsNumber .WizardPage p {
  font-size: 16px;
  line-height: 20px;
}

.Wizard.ClaimDunsNumber .WizardPage .ClaimForm .search-form {
  max-width: 640px;
  margin: 30px auto;
}

.dbia-credentials-link {
  position: -webkit-sticky;
  position: sticky;
  text-align: right;
  padding-top: 10px;
}
.dbia-dropdown {
  padding-left: 0px;
}
.dbia-dropdown .dropdown-toggle {
  color: #337ab7;
  text-decoration: none;
}
.dbia-login iframe {
  height: 100vh;
  border: 0;
}


.CompanyProfile .gmap-embed {
  margin: 30px -20px -25px -20px;
}

.CompanyProfile .panel {
  margin: 0 0 20px 0;
}

.CompanyProfile ul {
  padding-left: 18px;
}

.CompanyProfile .inpage-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

.CompanyProfile .inpage-nav ul {
  list-style: none;
  padding-left: 0;
}

.CompanyProfile .inpage-nav ul li {
  font-weight: 600;
  border-left: 5px solid #dee4ed;
  padding: 10px;
  cursor: pointer;
}

.CompanyProfile .inpage-nav ul li.active-li {
  border-color: #00d2c7;
  color: #00d2c7;
}

.CompanyProfile .panel-content {
  overflow: hidden;
}

.UniverseList .Universe-button {
  width: 100%;
  background-color: #f6f6f6;
  margin-bottom: 10px;
  border: 1px solid #9d9d9d;
  border-radius: 3px;
  color: black;
  padding: 20px;
}

.UniverseList .Universe-button .Title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #005172;
}

.UniverseList .Universe-button .Info {
  color: #333333;
  font-size: 14px;
  line-height: 19px;
  margin-top: 5px;
}

.UniverseList .add-universe-btn {
  float: right;
  margin-right: 15px;
}

.UniverseList .Content-table-last {
  padding: 0 20px;
}

.UniverseList .Title-table {
  background-color: #f6f6f6;
}

.UniverseList .Empty-list-message {
  margin: 0 30px;
}

.Change {
  border-bottom: 1px solid #9d9d9d;
  margin-top: 20px;
}

.Change .Change-info {
  height: 10px;
}

.Change .Change-info .Company-name {
  float: left;
}

.Change .Change-info .Created-at {
  float: right;
  right: 0;
  font-style: italic;
}

.Change .Change-details {
  padding-bottom: 5px;
}

.Change .Old-change {
  background-color: #eeeeee;
  margin: 0 5px 5px 0;
  padding: 2px 5px;
  text-decoration: line-through;
}

.Change .New-change {
  background-color: #ccebf5;
  padding: 2px 5px;
  margin: 0 5px 5px 0;
  display: inline-block;
}

.Changes {
  border: 1px solid #9d9d9d;
  border-radius: 3px;
  padding-bottom: 20px;
}

.Changes .Content-table-last .Change ~ .no-updates,
.no-updates ~ .no-updates {
  display: none;
}

.Company-info {
  padding-bottom: 15px;
}

.Company-info h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.Company-info span {
  font-size: 14px;
  line-height: 19px;
  display: block;
  margin-top: 5px;
}

.Company-info .DunsAbbr {
  display: inline-block;
  font-weight: bold;
}

.Company-info .remove {
  float: right;
  padding: 1px 12px;
  border-radius: 3px;
  border: none;
  background-color: #f2f2f2;
}

.SingleUniverse {
}

.SingleUniverse button {
  float: right;
}

.SingleUniverse .info-container {
  margin-top: 20px;
}

.SingleUniverse .company-container {
  border: 1px solid #9d9d9d;
  border-radius: 3px;
  padding-bottom: 20px;
}

.SingleUniverse .company-info {
  border-bottom: 1px solid #9d9d9d;
  margin-top: 20px;
}

.SingleUniverse .Content-table-last {
  padding: 0 20px;
}

.SingleUniverse .Title-table {
  background-color: #f6f6f6;
}

.report-modal .modal-dialog {
  width: 90%;
}

.reportsPurchaseTableInner .th-orderdate {
  min-width: 95px;
}

.reportsPurchaseTableInner .th-actions {
  min-width: 255px;
}

.UsageHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  background: #fff;
  z-index: 2;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ErrorFallback {
  display: flex;
  align-items: center;
  height: calc(100vh - 200px);
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

.ErrorFallback-public {
  margin-top: 70px;
}

.ErrorFallback .Contact-link {
  color: #009ece;
  cursor: pointer;
}

.ErrorFallback > img {
  margin-bottom: 30px;
}

.ErrorFallback > p:first-of-type {
  font-size: 30px;
  font-weight: 600;
  color: #005172;
  text-align: center;
}

.ErrorFallback > p:nth-of-type(2) {
  font-size: 16px;
  font-weight: 600;
  color: #005172;
  text-align: center;
}

.ErrorFallback > img {
  margin-top: 50px;
}

@media (max-width: 1290px) and (min-width: 601px) {
  .ErrorFallback > img {
    width: 350px;
    margin-top: 120px;
  }

  .ErrorFallback > p:first-of-type {
    font-size: 26px;
  }
}

@media (max-width: 600px) and (min-width: 491px) {
  .ErrorFallback > img {
    width: 350px;
    margin-top: 50px;
  }

  .ErrorFallback > p:first-of-type {
    font-size: 26px;
  }
}

@media (max-width: 490px) {
  .ErrorFallback > img {
    width: 300px;
    margin-top: 0;
  }

  .ErrorFallback > p:first-of-type {
    font-size: 26px;
  }
}

