var {
  font-style: inherit;
}

.nav-hr {
  width: 85%;
  border-top: 1px solid #022a3c;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  font-weight: 600;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
}

strong {
  font-weight: 600;
}

a:hover {
  text-decoration: none;
}

.mtop-30 {
  margin-top: 30px;
}

.mtop-50 {
  margin-top: 50px;
}

.mbot-20 {
  margin-bottom: 20px;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.Title-table {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  padding: 16px 20px;
  margin: 0 0 10px 0;
}

.Colored-circle {
  border-radius: 50px;
  background: #3e85c1;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  width: 62px;
  height: 62px;
  font-size: 25px;
}

.Colored-circle:hover {
  background: #3e85c1;
}

.light-text {
  color: #777;
}

/* .SearchableDropdown */
.form-group .SearchableDropdown__control {
  width: 100%;
  border-color: #d7e0e4;
  border-radius: 2px;
  box-shadow: none;
  font-size: 14px;
  color: #555;
}

.SearchableDropdown__control.SearchableDropdown__control--is-focused {
  border-color: #66afe9;
  border-radius: 0 !important;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.SearchableDropdown__placeholder {
  color: #999999 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}

.SearchableDropdown__menu {
  border-radius: 0 !important;
}

.SearchableDropdown__option {
  color: #333333 !important;
}

.SearchableDropdown__option.SearchableDropdown__option--is-focused {
  background-color: #f2f2f2;
  color: #333333;
}

.SearchableDropdown__option.SearchableDropdown__option--is-selected {
  background-color: #e4e4e4;
  color: #333333;
}
/* .SearchableDropdown END */

.SearchableDropdown__input input,
.StateDropdown__input input,
.CreatableEmail input {
  height: 31px;
}

.panel {
  border: 1px solid #d7e0e4;
  box-shadow: none;
  padding: 0;
  border-radius: 3px;
}

.panel .panel-heading {
  border-bottom: 1px solid #d7e0e4;
}

.panel .panel-heading h5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

button + button {
  margin-left: 10px;
}

.table-responsive {
  overflow-x: initial;
}

.call-verification-step {
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
  max-width: 640px;
}

.call-verification-step .svg-confirmation-call {
  margin-top: 100px;
}

.delivery-step {
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
  max-width: 760px;
}

/* Badges */

.badges .badge {
  margin-bottom: 5px;
}

.badges .badge:not(:first-of-type) {
  margin-left: 10px;
}

.badge {
  border-radius: 20px;
  padding: 6px 12px;
}

.badges.badges-5 {
  display: flex;
}

.badges.badges-5 .badge {
  padding: 12px 0;
  width: 20%;
  font-size: 20px;
  border-radius: 2px;
}

.badge.danger {
  background: #f79851;
}

.badge.active {
  background: #00d2c7;
}

.badge.success {
  background: #009ece;
}

.badge.inactive {
  background: #cf3154;
}
