.Profile,
.Company,
.Password {
  margin: 20px 10px 20px 10px;
  border: 1px solid #d7e0e4;
}
.Profile label,
.Company label,
.Password label,
.Password button[type='submit'],
.Company button[type='submit'],
.Profile button[type='submit'],
.Password .alert,
.Company .alert,
.Profile .alert {
  display: block;
  margin-top: 15px;
}
.Password input,
.Company input,
.Profile input,
.Password .alert,
.Company .alert,
.Profile .alert {
  max-width: 100%;
}
.Password button,
.Company button,
.Profile button {
  margin-top: 26px;
}
@media (max-width: 768px) {
  .Password .col-xs-12,
  .Company .col-xs-12,
  .Profile .col-xs-12 {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.Profile .Content-table-last,
.Company .Content-table-last,
.Password .Content-table-last {
  padding: 6px 20px 20px 20px;
}
.Profile .Image-container,
.Company .Image-container {
  display: inline-block;
  border-radius: 100px;
  position: relative;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.Company .Image-overlay,
.Profile .Image-overlay {
  position: absolute;
  opacity: 0;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.2);
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Profile .Image-overlay .Overlay-text,
.Company .Image-overlay .Overlay-text {
  color: #ffffff;
}
.Profile .Image-container:hover .Image-overlay,
.Company .Image-container:hover .Image-overlay {
  opacity: 1;
}
